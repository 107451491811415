* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.html {
    font-size: 62.5%;
}

body {
    background-color: grey;
}

.container {
    position: relative;

    height: 56rem;
    width: 87%;
    margin: 3.4rem auto;

    display: grid;
    grid-template-columns: 1fr 5fr;
}

.heading {
    margin: 0 0 0 0;

    width: 18rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: .6rem;


}


.heading h2 {
    font-size: 2.4rem;
}







.sidebar {
    background-color: white;

}

.side_items {
    display: grid;
    gap: 22rem;
    position: fixed;
}

.items {
    position: relative;
    top: 2rem;
    height: 100%;
    width: 18.5rem;

}


.items ul a {
    display: block;
    height: 100%;
    box-sizing: border-box;
    color: black;
    overflow: hidden;
    padding: 0.8rem 0 0.8rem 5rem;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 500;
    list-style: outside none none;
    text-decoration: none;
    line-height: 2rem;

}

.items ul a:hover {

    background-color: whitesmoke;
    color: red;
    transition: .4s;
    padding-right: 3rem;
}


.item2 ul a {

    display: block;
    line-height: 2rem;
    color: black;
    overflow: hidden;
    padding: 0.8rem 0 0.8rem 5rem;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 500;
    list-style: outside none none;
    text-decoration: none;

}

.item2 ul a:hover {

    background-color: whitesmoke;
    color: red;
    transition: .4s;
    padding-right: 3rem;
}



.right-section {

    background-color: #ecf2ff;

    overflow: hidden;
}






.right-sec-head {
    display: flex;
    width: 100%;
    height: 4rem;

    justify-content: space-between;

    background-color: white;
}

.top_heading {
    display: flex;
    column-gap: .5rem;

    position: relative;
    left: 2rem;
    padding-top: .9rem;
}

.top_heading .fa-greater-than {
    font-size: 1.2rem;
    padding-top: .6rem;
    font-weight: 900;
}

.top_icons {

    width: 9rem;
    position: relative;
    right: 2rem;
    display: flex;
    column-gap: 2rem;
    align-items: center;
    justify-content: center;
}

.top_icons .fa-user {
    background-color: #ecf2ff;
    padding: 9px 10px;
    border-radius: 100%;

}

.top_icons .fa-bell {
    background-color: #ecf2ff;
    padding: 9px 10px;
    border-radius: 100%;

}

.btn:hover {
    background-color: black;
}

.heading-rgt2 {

    width: 20rem;
    position: relative;
    top: 2rem;
    left: 2rem;
    display: flex;
    column-gap: .4rem;
}

.heading-rgt2 .fa-arrow-left-long {
    font-size: 1.3rem;
    padding-top: .4rem;
}


.heading-rgt2 .fa-greater-than {
    font-size: 1.3rem;
    padding-top: .4rem;
}

.right_parent {
    display: flex;
    column-gap: 7.8rem;


}

.parent_cards {

    position: relative;
    top: 3rem;
    left: 2rem;
    height: 40.1rem;
    width: 64rem;



}

.dynamic {

    width: 18rem;
    height: 24rem;
    position: relative;

    display: flex;
    justify-content: center;

}

.btn_divi {
    padding: 10px 65px;
    border-radius: .4rem;
    color: white;
    background-color: #ff4500cf;
    font-size: 1rem;
    border: none;
    transition: ease-out 0.3s;
}

.btn_divi:hover {

    box-shadow: inset 284px 0 0 0 whitesmoke;
    cursor: pointer;
    color:black;
    border:1px solid black;
   
    
}


.division_parent {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;

    z-index: 1;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.division_container {
    background-color: white;
    margin-top: 1rem;


    width: 40rem;
    height: 17rem;
    border-radius: .5rem;
}

.title_divi {
    border-bottom: 1px solid #0600ff17;
    height: 2.3rem;

}

.title_divi h5 {
    padding-top: .6rem;
    padding-left: .7rem;
}

.middle_division_sec {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    top: .4rem;

    height: 10rem;
}

.div_name {
    position: relative;
    left: .6rem;
}

.div_name p {
    color: grey;
    font-size: .9rem;
}

.div_name input[type=text] {
    position: relative;
    top: .1rem;
    width: 12rem;
    padding: 7px 0 8px 4px;
    border: none;
    background-color: #ecf2ff;
    border-radius: .5rem;
}

.div_data {
    display: flex;
    justify-content: space-between;


}

.incharge_class {
    position: relative;
    left: .8rem;
}

.incharge_class p {
    color: grey;
    font-size: .9rem;
}

.incharge_class select[type=text] {
    position: relative;
    top: .2rem;
    width: 12rem;
    padding: 8px 0 8px 4px;
    border: none;
    background-color: #ecf2ff;
    border-radius: .5rem;
}

.email_incharge p {
    color: grey;
    font-size: .9rem;
}

.email_incharge input[type=text] {
    width: 12rem;
    position: relative;
    top: .2rem;
    padding: 8px 0 8px 4px;
    border: none;
    background-color: #ecf2ff;
    border-radius: .5rem;
}

.total_employee {
    position: relative;
    right: .8rem;
}

.total_employee p {
    color: grey;
    font-size: .9rem;
}

.total_employee input[type=text] {
    width: 12rem;
    position: relative;
    top: .2rem;
    padding: 8px 0 8px 4px;
    border: none;
    background-color: #ecf2ff;
    border-radius: .5rem;
}


.division_btn_class {
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 1px inset #80808052;

    -webkit-box-shadow: 0px -2px 0px grey;
    -moz-box-shadow: 0px -0px 10px grey;
    box-shadow: 0px -3px 10px #80808066;

    height: 4.6rem;

}

.place_btn_class {

    position: relative;
    right: 3rem;
    width: 15rem;
    display: flex;
    justify-content: space-around;
}

.add_btn {
    padding: 8px 54px;
    background-color: #ff4500cf;
    border: none;
    color: white;
    border-radius: .3rem;
}

.cancel_btn {
    color: black;
    background-color: white;
    border: none;
    font-weight: bold;
}

.contain {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;



}


.cards {



    width: 20rem;
    height: 11rem;
    background-color: white;
    border-radius: .7rem;
    overflow: hidden;
    margin: 1rem 0rem;


}

.division {
    display: flex;
    justify-content: space-between;
    background-color: #029578c7;
    height: 2.6rem;


}

.dots {
    position: relative;
    top: .7rem;
    right: 1rem;
}

.fa-ellipsis {
    color: white;
    font-size: 1rem;
}

.division p {
    font-size: 1.3rem;
    color: white;

    position: relative;
    top: .5rem;
    left: .8rem;
}


.info {
    position: relative;
    left: .8rem;
    top: 1rem;

    display: flex;
    flex-direction: column;
    gap: .6rem;
}



@media (max-width: 1947px) {
    .contain {

        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .btn_divi {
        padding: 10px 50px;
        border-radius: .4rem;
        color: white;
        background-color: #ff4500cf;
        font-size: 1rem;
        border: none;
    }

    .right_parent {
        display: flex;
        column-gap: 3rem;


    }


}

@media (max-width: 1884px) {
    .right_parent {
        display: flex;
        column-gap: 0rem;


    }

    .btn_divi {
        padding: 9px 40px;
        border-radius: .4rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .8rem;
        border: none;
    }
}

@media (max-width: 1534px) {

    .btn_divi {
        padding: 9px 35px;
        border-radius: .4rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .7rem;
        border: none;
    }
}

@media (max-width: 1385px) {

    .contain {

        display: grid;
        grid-template-columns: 1fr;
    }

    .division_btn {
        position: relative;
        right: 2rem;
    }

    .btn_divi {
        padding: 9px 30px;
        border-radius: .4rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .6rem;
        border: none;
    }
}

@media (max-width: 1385px) {
    .division_btn {
        position: relative;
        right: 2rem;
    }

    .btn_divi {
        padding: 9px 25px;
        border-radius: .4rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .6rem;
        border: none;
    }

}

@media (max-width: 1090px) {
    .btn_divi {
        padding: 7px 20px;
        border-radius: .3rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .6rem;
        border: none;
    }


}

@media (max-width: 1042px) {
    .btn_divi {
        padding: 6px 16px;
        border-radius: .2rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .6rem;
        border: none;
    }


}

@media (max-width: 1002px) {
    .btn_divi {
        padding: 6px 14px;
        border-radius: .2rem;
        color: white;
        background-color: #ff4500cf;
        font-size: .5rem;
        border: none;
    }


}